.contactform__contactform__2dFyI{
    width:32vw;
    height: 100%;
}
.contactform__title__30owj{
    font-weight: bold;
    font-size: 18px;
    color: #3a3a3a;
}
.contactform__form__2LGkA{
    margin-bottom: 20px;
}