/* @font-face {
    font-family: myFirstFont;
    src: url('./assets/fonts/Century Gothic 400.ttf');
} */
body {
	font-family: "Lato", sans-serif;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
    margin: 0;
    color:#343434;
	box-sizing: border-box;
}
header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 75px;
	background: #000000;
    z-index: 10;
    opacity: 0.8;
    -webkit-box-shadow: 0 0 20px #000;
}
.only_text_center {
	text-align: center;
}
.center {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.slider-content {
	color: white
}

.slider-content:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .9)));
	background: linear-gradient(transparent, rgba(0, 0, 0, .9));
	bottom: 0;
	left: 0;
}
.error {
    border: 2px solid #e53e3e;
}

.errorText {
    color: #e53e3e;
	margin-bottom: 16px;
}

.styles__page__3Pn7C {
	width: 100%;
	margin: auto;
	height: 100%;
}
.styles__container__23klc{
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 640px;
}
.styles__header_bg__1FeSS {
    border-bottom: 1px solid transparent;
    border: 1px solid transparent;
    background: #010101;
    position: absolute;
    display: block;
    color: #fff;
    min-height: 50px;
    margin: 0;
    opacity: 1;
    width: 100%;
}

.styles__sm_slider__2LbLw{
    background-color: rgb(179, 179, 179);
    margin: 120px auto 40px auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
}

.styles__block_container__3HNNg{
    padding: 120px 20px 0 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: auto;
    margin-left: auto;
    width: 80%;
}
.styles__fir_row__2Ju8z{
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.styles__sec_row__mt-kk{
    margin-bottom: 40px;
    position: relative;
    margin-right: auto;
    margin-left:300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.styles__header_container__3ViuV{
    padding: 5px 5px 5px 5px;
    margin-right: auto;
    margin-left: auto;
    width: 90vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.styles__header_link__2JNjw{
    font-family: Century Gothic, sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    background: transparent;
    text-decoration: none;
    font-weight: 900;
    color: #fff;
    margin: 15px -15px 0 0;
    font-size: 28px;
}
.styles__header_nav_div__3zjPG{
    margin: 0;
}
.styles__header_ul__aSrnU{
    list-style-type: none;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    overflow: hidden;
}
.styles__header_li__24Tbz{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    float: left;
}
.styles__ul_li_a__3cCSy{
    text-decoration: none;
    padding: 28px 13px 27px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    -webkit-box-shadow: 0 none;
    box-shadow: 0 none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    /* background-color: #dddddd; */
}
.styles__ul_li_a__3cCSy:hover{
    color: #5C9F24;
}

form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	width: 100%;
	margin: 0px;
}

form input {
	width: 100%;
	padding: 16px;
	background-color: #edf2f7;
	border: none;
	margin-bottom: 16px;
	font-size: 14px;
	border: 2px solid #a0aec0;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

form textarea {
	width: 100%;
	padding: 16px;
	background-color: #edf2f7;
	border: none;
	margin-bottom: 16px;
	font-size: 14px;
	border: 2px solid #a0aec0;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	height: 150px;
}

form button[type="submit"] {
	background-color: #48bb78;
	color: #ffffff;
	font-size: 14px;
	border: none;
	padding: 16px;
	width: 100%;
}
.styles__upbutton__2Qqkb{
    position:fixed;
    width: 48px;
    height: 45px;
    bottom: 40px;
    right: 20px;
    text-align: center;
    background-color: #FAD530;
    border-radius: 2px;
    border-color: #FAD530;
    color: white !important;
    font-size: 22px;
    font-weight: bold;
    padding-top: 3px;
}

@media (min-width: 300px){
    .styles__blodtext__17bmN{
        text-transform: uppercase;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        font-weight: 700;
        font-size: 40px;
        width: auto;
    }
    .styles__block__28u57{
        background-color: rgb(179, 179, 179);
        margin-bottom: 15px;
        margin-right: 13px;
        margin-left: 13px;
        height: 400px;
        width: 30%;
        float: left;
    }  
} 