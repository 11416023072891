.smslider__image__2OtPn{
	border-radius: 70px;
	margin-top: 10px;
    width: 50vw;
    height: 23vw;
}
.smslider__title__9PV_n{
	font-size: 30px;
	color: #3a3a3a;
	font-weight: 300;
}
.smslider__container__XArZL{
	font-family: Century Gothic, sans-serif;
	height: 100%;
	line-height: 1.6em;
	text-align: center;
	color: #777;
    margin: 20px 0 40px 40px;
}
.smslider__text__1w8lo{
    text-align: justify;
	font-size: 20px;
	margin-top: 30px;
    margin-left: 5vw;
    margin-right: 5vw;
}
.smslider__slider__1_pgf {
    padding: 5px;
    margin-top: 8vw;
    margin-left: auto;
    margin-right: auto;
	position: relative;
	width: 60%;
	height: 700px;
    overflow: hidden
    /* background-color: beige; */
}
.smslider__slider__1_pgf a.smslider__previousButton__-WKsF, .smslider__slider__1_pgf a.smslider__nextButton__1vdDH {
	font-size: 22px;
	line-height: 0;
	display: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
	z-index: 1;
	color: #333;
	padding: 10px;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	/* prevent jump effect when scaling */
}
.smslider__slider__1_pgf a.smslider__previousButton__-WKsF:not(.smslider__disabled__kEQfy):hover, .smslider__slider__1_pgf a.smslider__nextButton__1vdDH:not(.smslider__disabled__kEQfy):hover {
	-webkit-transform: translateY(-50%) scale(1.25);
	        transform: translateY(-50%) scale(1.25);
	cursor: pointer;
}
.smslider__slider__1_pgf a.smslider__previousButton__-WKsF {
	left: 20px;
}
.smslider__slider__1_pgf a.smslider__nextButton__1vdDH {
	right: 20px;
}

.smslider__slide__1xkZv {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}

.smslider__slide__1xkZv.smslider__hidden__1WUTl {
	visibility: hidden;
}

.smslider__slide__1xkZv.smslider__previous__2AQjw {
	left: -100%;
}

.smslider__slide__1xkZv.smslider__current__2QhQx {
	left: 0;
}

.smslider__slide__1xkZv.smslider__next__1O4Ko {
	left: 100%;
}

.smslider__slide__1xkZv.smslider__animateIn__2qOSZ,
	.smslider__slide__1xkZv.smslider__animateOut__IW2Nl {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.smslider__slide__1xkZv.smslider__animateIn__2qOSZ.smslider__previous__2AQjw,
		.smslider__slide__1xkZv.smslider__animateIn__2qOSZ.smslider__next__1O4Ko {
	left: 0;
	visibility: visible;
}

.smslider__slide__1xkZv.smslider__animateOut__IW2Nl.smslider__previous__2AQjw {
	left: 100%;
}

.smslider__slide__1xkZv.smslider__animateOut__IW2Nl.smslider__next__1O4Ko {
	left: -100%;
}
