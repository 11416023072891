.block__block__3Cewq{
    width: 80vw;
    padding: 80px 20px 20px 20px;
    margin: 50px auto auto auto;
}
.block__body_block__2qQ-U{
    max-height: 100vw;
    min-height: 30vw;
    padding-left:4vw;
    padding-right:4vw;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.block__body_block2__2XxxT{
    max-height: 100vw;
    min-height: 30vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    padding-left:16vw;
    padding-right:16vw;
}
.block__title__3-fQu{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1em;
    color: #3a3a3a;
    margin-bottom: 10vh;
    font-family: Century Gothic, sans-serif;
}
@media (max-width: 900px){
    .block__body_block__2qQ-U{
        max-height: 100vw;
        min-height: 70vw;
        padding-left:5vw;
        padding-right:5vw;
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .block__block__3Cewq{
        width: 90vw;
        padding: 80px 10px 10px 10px;
        margin: 50px auto auto auto;
    }
}