.contact__contact__1o2Sd{
    margin: 150px auto auto auto;
    width: 66vw;
    height: 100%;
    font-family: Century Gothic, sans-serif;
}
.contact__title__2FXYV{
    text-align: center;
    color: #333333;
    font-family: Century Gothic, sans-serif;
    font-size: 40px;
}
.contact__description__aLLfW{
    text-align: center;
    font-family: Century Gothic, sans-serif;
    font-size: 25px;
}
.contact__contain__1XOf2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}