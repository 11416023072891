.footer__footer__G_tSf {
    float: left;
    font-family: Century Gothic, sans-serif;
    text-align: center;
    width: 100%;
    background: #212121;
    color: #fff;
    margin-top: 100px;
    padding: 70px 0 70px 0;
}
.footer__text__1aKX9{
    font-size: 15px;
    font-style: italic;
    margin-top: 30px;
}