.bgslider__slider__GWfU7 {
	position: relative;
	width: 100%;
	height: 60vw;
	overflow: hidden
    /* margin: 40px; */
}

.bgslider__slider__GWfU7 a.bgslider__previousButton__ofcp3, .bgslider__slider__GWfU7 a.bgslider__nextButton__jD67O {
	font-size: 22px;
	line-height: 0;
	display: block;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
	z-index: 1;
	padding: 10px;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	/* prevent jump effect when scaling */
}

.bgslider__slider__GWfU7 a.bgslider__previousButton__ofcp3:not(.bgslider__disabled__1TIJH):hover, .bgslider__slider__GWfU7 a.bgslider__nextButton__jD67O:not(.bgslider__disabled__1TIJH):hover {
	-webkit-transform: translateY(-50%) scale(1.25);
	        transform: translateY(-50%) scale(1.25);
	cursor: pointer;
}

.bgslider__slider__GWfU7 a.bgslider__previousButton__ofcp3 svg polygon, .bgslider__slider__GWfU7 a.bgslider__nextButton__jD67O svg polygon {
	fill: #ffd800;
}

.bgslider__slider__GWfU7 a.bgslider__previousButton__ofcp3 {
	left: 20px;
}

.bgslider__slider__GWfU7 a.bgslider__nextButton__jD67O {
	right: 20px;
}

.bgslider__sliderContent__3YuwZ {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	text-align: center;
	background-size: cover !important
}

.bgslider__sliderContent__3YuwZ:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .9)));
	background: linear-gradient(transparent, rgba(0, 0, 0, .9));
	bottom: 0;
	top: 0;
}

.bgslider__sliderContent__3YuwZ.bgslider__hidden__NoIDn {
	visibility: hidden;
}

.bgslider__sliderContent__3YuwZ.bgslider__previous__u_ETT {
	top: -100%;
}

.bgslider__sliderContent__3YuwZ.bgslider__current__3ALNl {
	top: 0;
}

.bgslider__sliderContent__3YuwZ.bgslider__next__3pULY {
	top: 100%;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy,
	.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT,
		.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY {
	top: 0;
	visibility: visible;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT p, .bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY p {
	-webkit-transition-delay: 1.1s;
	        transition-delay: 1.1s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT button, .bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY button {
	-webkit-transition-delay: 1.3s;
	        transition-delay: 1.3s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT section img, .bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY section img {
	-webkit-transition-delay: 1.3s;
	        transition-delay: 1.3s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT section span, .bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY section span {
	-webkit-transition-delay: 1.4s;
	        transition-delay: 1.4s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__previous__u_ETT section span strong, .bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy.bgslider__next__3pULY section span strong {
	-webkit-transition-delay: 1.5s;
	        transition-delay: 1.5s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l.bgslider__previous__u_ETT {
	top: 100%;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l.bgslider__next__3pULY {
	top: -100%;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l h1 {
	-webkit-transition-delay: .3s;
	        transition-delay: .3s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l p {
	-webkit-transition-delay: .2s;
	        transition-delay: .2s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l section span {
	-webkit-transition-delay: .1s;
	        transition-delay: .1s;
}

.bgslider__sliderContent__3YuwZ.bgslider__animateOut__3812l section span strong {
	-webkit-transition-delay: 0s;
	        transition-delay: 0s;
}

.bgslider__sliderContent__3YuwZ.bgslider__current__3ALNl h1, 
		.bgslider__sliderContent__3YuwZ.bgslider__current__3ALNl button, 
		.bgslider__sliderContent__3YuwZ.bgslider__current__3ALNl p, 
		.bgslider__sliderContent__3YuwZ.bgslider__current__3ALNl section *, 
		.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy h1, 
		.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy button, 
		.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy p, 
		.bgslider__sliderContent__3YuwZ.bgslider__animateIn__34aAy section * {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	-webkit-transition-delay: .9s;
	        transition-delay: .9s;
	opacity: 1;
}

.bgslider__sliderContent__3YuwZ .bgslider__inner__2Wl1w {
	padding: 0 70px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.bgslider__sliderContent__3YuwZ h1 {
	font-weight: 300;
	margin: 0 auto;
	max-width: 840px;
	color: #FFFFFF;
	font-size: 50px;
	font-family: Century Gothic, sans-serif;
	line-height: 1;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	-webkit-transform: translateX(-20px);
	transform: translateX(-20px);
	opacity: 0;
}

.bgslider__sliderContent__3YuwZ p {
	color: #FFFFFF;
	font-size: 14px;
	line-height: 1.5;
	margin: 20px auto 30px;
	max-width: 640px;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	-webkit-transform: translateX(20px);
	        transform: translateX(20px);
	opacity: 0;
}

.bgslider__sliderContent__3YuwZ button {
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	-webkit-transform: translateX(20px);
	        transform: translateX(20px);
	opacity: 0;
}

.bgslider__sliderContent__3YuwZ section {
	position: absolute;
	bottom: 20px;
	top: 20px;
}

.bgslider__sliderContent__3YuwZ section * {
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.bgslider__sliderContent__3YuwZ section span {
	color: rgba(255, 255, 255, .5);
	font-size: 12px;
	display: inline-block;
	text-align: top;
	line-height: 1.4;
	vertical-align: middle;
	margin-left: 10px;
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px);
	opacity: 0;
}

.bgslider__sliderContent__3YuwZ section span strong {
	color: #FFFFFF;
	font-size: 14px;
	display: block;
	-webkit-transform: translateX(10px);
	        transform: translateX(10px);
	opacity: 0;
}

.bgslider__sliderContent__3YuwZ section img {
	width: 40px;
	height: 40px;
	border: solid 2px rgba(255, 255, 255, .5);
	border-radius: 100%;
	vertical-align: middle;
	-webkit-transition: all .5s ease;
	transition: all .5s ease;
	-webkit-transform: translateX(-20px);
	        transform: translateX(-20px);
	opacity: 0;
}

