.header-module__header_container__3_-_i{
    padding: 10px 0 10px 0;
    width: 100vw;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: #010101;
    position: fixed;
    color: #fff;
    /* opacity: 0.8; */
}
.header-module__row__2oBCa{
    width: 70vw;
    /* opacity: 0.8; */
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.header-module__header_link_div__G0lxb{
    margin-top: 15px;
}
.header-module__header_link__3lUxy{
    font-family: Century Gothic, sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    background: transparent;
    text-decoration: none;
    font-weight: 900;
    margin-top: 15px;
    font-size: 28px;
    color:white;
}
.header-module__header_link__3lUxy:hover{
    text-decoration: none;
    color:white;
}
.header-module__header_nav_div__S9TP-{
    margin: 0;
}
.header-module__header_li__1uE3j{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    float: left;
}
.header-module__ul_li_a__KTPFp{
    text-decoration: none;
    padding: 28px 13px 27px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    -webkit-box-shadow: 0 none;
    box-shadow: 0 none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.header-module__ul_li_a__KTPFp:hover{
    text-decoration: none;
    color: #5C9F24;
}