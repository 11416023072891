.location__location__TAsAW{
    width:32vw;
    height: 100%;
    margin-left: 20px;
}
.location__title__vS8sE{
    font-weight: bold;
    font-size: 18px;
    color: #3a3a3a;
}
.location__map__3Yz2z{
    margin-top: -6px;
    height: 350px;
    width: 100%;
}