.blog__blog__1xbdL{
    width: 22vw;
    min-height: 85vh;
    height: 100%;
    color: #777;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #f5f5f5;
}
.blog__title__3uAMY{
    color: #343434;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    font-family: Century Gothic, sans-serif;
}
.blog__image__2N8X0{
    width: 100%;
    height: 200px;
}
.blog__content__Fy14N{
    color: #333333;
    text-align: center;
    font-family: Century Gothic, sans-serif;
    margin: 20px 5px 5px 5px;
    font-size: 18px;
    line-height: 1.5em;
}