.withus__withus__2uPxM{
    margin-top: 150px;
    text-align: center;
    font-family: Century Gothic, sans-serif;
}
.withus__title__2Uy5E{
    color: #333333;
    font-size: 40px;
}
.withus__description__2v8cZ{
    text-align: center;
    font-size: 25px;
}
.withus__form__2EhjD{
    width: 40vw;
    margin: auto;
}